import React from "react";
import { Button, Image } from "react-bootstrap";
import "./Caracteristicas.scss";
import { BsFillTreeFill } from "react-icons/bs";
import mapImage from "../../../assets/img/mapa.JPG";
import { BrochureLink } from "../../common/BrochureLink";
import { Map } from "../../common/Map";

const Caracteristicas = () => {
  const features = [
    { text: "A 30 minutos del aeropuerto de Balmaceda." },
    { text: "Entrega inmediata." },
    { text: "Rol propio" },
    { text: "Parcelas desde 5000 m2" },
    { text: "Autorizadas acorde a normativa legal" },
    { text: "Acceso inmediato a orilla de camino" },
    { text: "Terrenos con hermosas vistas" },
    { text: "Terrenos planos y semiplanos" },
    { text: "Factibilidad real de Agua y Electricidad (poste)" },
    { text: "A 15 minutos de más de 6 lagos de la región." },
    { text: "A 25 minutos de la ciudad de Coyhaique (Sector Valle Simpson)." },
    { text: "A 60 minutos del Parque Nacional Cerro Castillo" },
    { text: "A 90 minutos del Puerto Ingeniero Ibañez" },
  ];

  return (
    <section id="caracteristicas" className="container">
      <h2 id="title">Características y Ubicación</h2>
      {/* <BrochureLink /> */}
      <div id="contentContainer">
        <div id="mapContainer">
          <Map />
        </div>
        <div id="caracteristicasContainer">
          {features.map((f, i) => (
            <div key={i} className="caracteristicaContainer">
              <h4 className="caracteristicaIcon">
                <BsFillTreeFill />
              </h4>
              <h5>{f.text}</h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Caracteristicas };
